import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import Navigation from "../components/navigation";
import AdPageHero from "../components/ad-page-hero";
import BasicLayout from "../components/basic-layout";
import ImageFrame from "../images/Frame.svg";
import { Button } from "react-bootstrap";
import ReviewCardAd from "../components/review-card-ad";
import ReviewSectionAd from "../components/review-section-ad";
import { InlineWidget } from "react-calendly";
import { data } from "jquery";
import Hero from "../components/hero";
import OSSection from "../components/other-services-section";
import OneLogo from "../images/1-yellow.svg";
import TwoLogo from "../images/2-yellow.svg";
import ThreeLogo from "../images/3-yellow.svg";

const ItPageTemplate = ({
	data: { post, site, otherPosts, blogImg, sideImg1, heroImg },
}) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},

			{
				"@type": "ListItem",
				position: "2",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/${post.slug}`,
					id: `${siteUrl}/${post.slug}`,
				},
			},
		],
	};

	const schema = post.itPageFields.contentForHeadTag;

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			<GatsbySeo
				title={post?.seo?.title}
				description={post?.seo?.metaDesc}
				language="en"
				openGraph={{
					type: "article",
					url: `${siteUrl}/${post.slug}`,
					title: `${post?.seo?.opengraphTitle}`,
					description: `${post?.seo?.opengraphDescription}`,
					images: [
						{
							url: `${post?.seo?.twitterImage?.link}`,
							width: `${post?.seo?.twitterImage?.mediaDetails.width}`,
							height: `${post?.seo?.twitterImage?.mediaDetails.height}`,
							alt: `${post?.seo?.twitterImage?.altText}`,
						},
					],
				}}
			/>
			<section>
				<Hero
					button1="primary-link-dark"
					button1Text="Book a discovery call"
					button1Link="#form"
					button1Var="secondary"
					button2={null}
					button3={null}
					backgroundImage={heroImg.gatsbyImage}
					title={post.title}
					description={post.description}
				/>
			</section>
			<section className="bg-light-grey py-5 py-lg-7">
				<Container>
					<Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
						<Col lg={6} className=" ">
							<h2 className="display-5 text-primary mb-5">
								{post.itPageFields.section1.title}
							</h2>
							{parse(post.itPageFields.section1.copy)}
						</Col>
						<Col lg={6}>
							<div
								style={{
									overflow: "hidden",
									borderRadius: "15px",
									boxShadow: "0px 3px 99px #00000027",
								}}
							>
								<GatsbyImage
									className="w-100"
									image={post.itPageFields.section1.image.gatsbyImage}
									alt={post.itPageFields.section1.image.altText}
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-light-grey position-relative pb-5 pb-lg-7">
				<div className="position-relative bg-dark-grey">
					<Container className="pb-10 pt-7">
						<Row className="align-items-center ">
							<Col lg={7} className=" ">
								<h2 className=" text-secondary display-4 mb-4">
									{post.itPageFields.section2.title}
								</h2>
								<p className="text-white">
									{post.itPageFields.section2.description}
								</p>
							</Col>
						</Row>
					</Container>
				</div>
				<div style={{ top: "-5rem" }} className="position-relative">
					<Container>
						<Row className="g-6 g-lg-3 justify-content-center g-xl-6 h-100">
							<Col style={{ minHeight: "100%" }} md={9} lg={4}>
								<div
									className="bg-white  position-relative h-100 p-4 "
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 50px #00000012",
										width: "100%",
									}}
								>
									<div className="d-block text-start ">
										<h3 className="text-primary text-center">
											{post.itPageFields.section2.box1Title}
										</h3>
										<div className="text-primary ps-4">
											{parse(post.itPageFields.section2.box1Copy)}
										</div>
										<div style={{ height: "60px" }}></div>
										<div className="position-absolute w-100 bottom-0 start-0 pb-4 px-4">
											<Button
												className={`mt-4  fs-5 w-100 d-none d-md-inline-block  py-2 px-4 white-link`}
												variant="primary"
												href="#form"
											>
												Book a discovery call
											</Button>
										</div>
									</div>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} md={9} lg={4}>
								<div
									className="bg-white  position-relative  h-100 p-4 "
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 50px #00000012",
										width: "100%",
									}}
								>
									<div className="d-block text-start ">
										<h3 className="text-primary text-center">
											{post.itPageFields.section2.box2Title}
										</h3>
										<div className="text-primary ps-4">
											{parse(post.itPageFields.section2.box2Copy)}
										</div>
										<div style={{ height: "60px" }}></div>
										<div className="position-absolute w-100 bottom-0 start-0 pb-4 px-4">
											<Button
												className={`mt-4  fs-5 w-100 d-none d-md-inline-block  py-2 px-4 white-link`}
												variant="primary"
												href="#form"
											>
												Book a discovery call
											</Button>
										</div>
									</div>
								</div>
							</Col>
							<Col style={{ minHeight: "100%" }} md={9} lg={4}>
								<div
									className="bg-white  position-relative  h-100 p-4 "
									style={{
										borderRadius: " 15px",
										boxShadow: "0px 3px 50px #00000012",
										width: "100%",
									}}
								>
									<div className="d-block text-start ">
										<h3 className="text-primary text-center">
											{post.itPageFields.section2.box3Title}
										</h3>
										<div className="text-primary ps-4">
											{parse(post.itPageFields.section2.box3Copy)}
										</div>
										<div style={{ height: "60px" }}></div>
										<div className="position-absolute w-100 bottom-0 start-0 pb-4 px-4">
											<Button
												className={`mt-4  fs-5 w-100 d-none d-md-inline-block  py-2 px-4 white-link`}
												variant="primary"
												href="#form"
											>
												Book a discovery call
											</Button>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</section>
			{post.itPageFields.showSection3 && (
				<section style={{ background: "#FDFDFD" }} className=" pb-5 pb-lg-7">
					<Container>
						<Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
							<Col lg={7}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={post.itPageFields.section3.image.gatsbyImage}
										alt={post.itPageFields.section3.image.altText}
									/>
								</div>
							</Col>
							<Col lg={5} className=" ">
								<p className="ssp-bold text-uppercase text-light-blue">
									{post.itPageFields.section3.smallHeading}
								</p>
								<h2 className="display-5 text-primary mb-4">
									{post.itPageFields.section3.title}
								</h2>
								{parse(post.itPageFields.section3.copy)}
							</Col>
						</Row>
					</Container>
				</section>
			)}
			<section className=" pb-xl-7 bg-light-grey">
				<Container>
					<Row id="form">
						<Col className="text-center">
							{" "}
							<h2 className="display-5 text-primary mb-3">
								Book a discovery meeting
							</h2>
							<p>
								Book a time and day that works for you through our online
								calendar to discuss your project.
							</p>
						</Col>
					</Row>
					<Row>
						<Col className="d-xl-none">
							<InlineWidget
								className="calender"
								styles={{ minWidth: "100%", height: "1150px" }}
								url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
							/>
						</Col>
						<Col className="d-none d-xl-block">
							<InlineWidget
								className="calender"
								styles={{ minWidth: "100%", height: "700px" }}
								url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
							/>
						</Col>
					</Row>
				</Container>
			</section>
			<section id="benefits" className="py-5 pt-lg-7 bg-dark-grey">
				<Container className="">
					<Row className="justify-content-between align-items-center">
						<Col>
							<h2 className="display-2  text-uppercase text-center ssp-bold text-white mb-5">
								{post.itPageFields.numberSection.title}
							</h2>
						</Col>
					</Row>
					<Row className="gx-xl-6 gy-5 justify-content-center">
						<Col className="position-relative" md={6} lg={4}>
							<div
								style={{ top: "-7rem" }}
								className="position-absolute text-center w-100 h-100"
							>
								<img src={OneLogo} className="w-md-40 w-30" />
							</div>
							<div className="px-xl-5 pt-5 position-relative">
								<h3 className="fs-1 text-white">
									{post.itPageFields.numberSection.number1Heading}
								</h3>
								<p className="text-white">
									{post.itPageFields.numberSection.number1Copy}
								</p>
							</div>
						</Col>
						<Col className="position-relative" md={6} lg={4}>
							<div
								style={{ top: "-7rem" }}
								className="position-absolute text-center w-100 h-100"
							>
								<img src={TwoLogo} className="w-md-40 w-30" />
							</div>
							<div className="px-xl-5 pt-5 position-relative">
								<h3 className="fs-1 text-white">
									{post.itPageFields.numberSection.number2Heading}
								</h3>
								<p className="text-white">
									{post.itPageFields.numberSection.number2Copy}
								</p>
							</div>
						</Col>
						<Col className="position-relative " md={6} lg={4}>
							<div
								style={{ top: "-7rem" }}
								className="position-absolute w-100 text-center h-100"
							>
								<img src={ThreeLogo} className="w-md-40 w-30" />
							</div>
							<div className="px-xl-5 pt-5 position-relative">
								<h3 className="fs-1 text-white">
									{post.itPageFields.numberSection.number3Heading}
								</h3>
								<p className="text-white">
									{post.itPageFields.numberSection.number3Copy}
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<OSSection image={sideImg1.gatsbyImage} imageAlt={sideImg1.altText} />
		</Layout>
	);
};

export default ItPageTemplate;

export const pageQuery = graphql`
	query ItPageById($id: String!) {
		sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
			altText
			gatsbyImage(
				width: 1920
				quality: 75
				formats: [WEBP]
				layout: CONSTRAINED
				placeholder: BLURRED
			)
		}
		heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
			altText
			mediaDetails {
				height
				width
			}
			link
			gatsbyImage(
				width: 1920
				quality: 75
				formats: [WEBP]
				layout: CONSTRAINED
				placeholder: BLURRED
			)
		}
		post: wpItPage(id: { eq: $id }) {
			title
			slug
			itPageFields {
				contentForHeadTag
				description
				showSection3
				section1 {
					copy
					title
					image {
						altText
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
				section2 {
					title
					description
					box1Copy
					box1Title
					box2Copy
					box2Title
					box3Copy
					box3Title
				}
				section3 {
					copy
					smallHeading
					title
					image {
						altText
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
				numberSection {
					title
					number1Copy
					number1Heading
					number2Copy
					number2Heading
					number3Copy
					number3Heading
				}
			}
			seo {
				metaDesc
				title
				twitterImage {
					altText
					mediaDetails {
						height
						width
					}
					link
					gatsbyImage(
						width: 1920
						quality: 75
						formats: [WEBP]
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
				opengraphTitle
				opengraphDescription
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
